var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("el-button", { on: { click: _vm.goBackAction } }, [
                _vm._v("Back"),
              ]),
              _c("span", { staticClass: "ref" }, [
                _vm._v(" / "),
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.estimate_id) +
                      " / Invoice " +
                      _vm._s(_vm.invoice.sequence) +
                      "/" +
                      _vm._s(_vm.payment_plan.quantity) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "span",
                { staticClass: "header-right" },
                [
                  _c(
                    "span",
                    { staticStyle: { "font-size": "11px" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-weight": "bolder",
                            "margin-right": "5px",
                          },
                        },
                        [_vm._v(" Status: ")]
                      ),
                      _c(
                        "el-tag",
                        {
                          attrs: {
                            type: _vm.invoice.status ? "info" : "danger",
                            "disable-transitions": true,
                            size: "mini",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("capitalize")(
                                  _vm.invoice.status ?? "Missing"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  !_vm.editing_invoice.invoice_id
                    ? _c(
                        "el-popconfirm",
                        {
                          staticClass: "delete-confirm",
                          attrs: {
                            placement: "top",
                            title:
                              "Are you sure you want to delete this invoice?",
                            "hide-icon": "",
                          },
                          on: { confirm: _vm.handleDeleteInvoice },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "reference",
                                plain: "",
                                type: "danger",
                              },
                              slot: "reference",
                            },
                            [_vm._v(" Delete invoice ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("hr"),
          _c("paymentplan-invoice", {
            attrs: {
              payment_plan: _vm.payment_plan,
              invoice: _vm.editing_invoice,
              estimate: _vm.estimate,
            },
            on: { update: _vm.handleInvoiceUpdate },
          }),
          _c(
            "div",
            { staticClass: "section-footer" },
            [
              _vm.editing_invoice.invoice_id
                ? [
                    !_vm.linked_invoice
                      ? _c(
                          "el-alert",
                          { attrs: { closable: false, type: "warning" } },
                          [
                            _vm._v(
                              " This invoice has not been imported. Refresh invoices to link it to this payment plan. "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "row" }, [
                      _vm.invoice_url
                        ? _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:copy",
                                      value: _vm.invoice_url,
                                      expression: "invoice_url",
                                      arg: "copy",
                                    },
                                  ],
                                  attrs: { readonly: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showNotification(
                                        "Xero invoice link"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Xero invoice link ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "col right" },
                        [
                          _vm.linked_invoice
                            ? _c(
                                "el-button",
                                { on: { click: _vm.handleViewInvoice } },
                                [_vm._v(" View invoice ")]
                              )
                            : _vm._e(),
                          _vm.can_send_email
                            ? [
                                _vm.email_sent
                                  ? _c("div", { staticClass: "email-sent" }, [
                                      _vm._v(" Sent "),
                                    ])
                                  : _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          plain: "",
                                          type: "primary",
                                          loading: _vm.sending,
                                        },
                                        on: { click: _vm.handleSendEmail },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              this.linked_invoice
                                                .sent_to_contact
                                                ? "Resend email"
                                                : "Send email"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                    _vm.invoice.last_sent
                      ? _c("div", { staticClass: "last-sent" }, [
                          _vm._v(
                            " Last sent " +
                              _vm._s(
                                _vm._f("dateformat")(
                                  _vm.invoice.last_sent.toDate(),
                                  "DD/MM/YYYY"
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                : [
                    _c(
                      "el-alert",
                      { attrs: { type: "info", closable: false } },
                      [
                        _vm._v(
                          " Changes will apply to this invoice only, and do not affect the rest of the payment plan. "
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col right" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { disabled: _vm.is_disabled },
                              on: { click: _vm.handleSaveInvoice },
                            },
                            [_vm._v(" Save changes ")]
                          ),
                          _c(
                            "el-popover",
                            {
                              attrs: { placement: "top", trigger: "manual" },
                              model: {
                                value: _vm.submitConfirmVisible,
                                callback: function ($$v) {
                                  _vm.submitConfirmVisible = $$v
                                },
                                expression: "submitConfirmVisible",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "send-confirm-popover" },
                                [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(
                                      " The due date is either today or in the past."
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " Are you sure you want to submit to Xero? "
                                    ),
                                  ]),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "danger", size: "mini" },
                                      on: {
                                        click: () =>
                                          _vm.handleSubmitInvoice(true),
                                      },
                                    },
                                    [_vm._v(" Confirm & submit ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "submit-button",
                                  attrs: {
                                    slot: "reference",
                                    disabled:
                                      !_vm.is_accepted ||
                                      _vm.editing_invoice.is_scheduled,
                                    loading: _vm.sending,
                                    plain: "",
                                    type: "primary",
                                  },
                                  on: {
                                    click: () => _vm.handleSubmitInvoice(false),
                                  },
                                  slot: "reference",
                                },
                                [
                                  _vm.submitConfirmVisible
                                    ? _c("span", [_vm._v(" Cancel submit ")])
                                    : _c("span", [_vm._v("Submit to Xero")]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
            ],
            2
          ),
          !_vm.has_contact_emails && !_vm.invoice_paid
            ? _c(
                "el-alert",
                {
                  staticClass: "email-warning",
                  attrs: { type: "error", closable: false },
                },
                [
                  _vm._v(" This payment plan is not configured for emailing. "),
                  _c(
                    "el-button",
                    {
                      staticClass: "edit-paymentplan-contacts",
                      attrs: { size: "small", plain: "", type: "danger" },
                      on: { click: _vm.handleEditContacts },
                    },
                    [_vm._v(" Edit contacts ")]
                  ),
                ],
                1
              )
            : _vm.can_send_email
            ? _c(
                "el-alert",
                { staticClass: "email-list", attrs: { closable: false } },
                [
                  _vm._v(
                    " The following contacts will receive this invoice when emailed: "
                  ),
                  _c(
                    "ul",
                    [
                      _vm.contact_addressee.name
                        ? _c("li", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.contact_addressee.name) +
                                " <" +
                                _vm._s(_vm.contact_addressee.email) +
                                "> "
                            ),
                          ])
                        : _c("li", [
                            _vm._v(
                              " " + _vm._s(_vm.contact_addressee.email) + " "
                            ),
                          ]),
                      _vm._l(_vm.contact_cc, function (contact) {
                        return _c(
                          "li",
                          { key: contact.email },
                          [
                            _c(
                              "el-tag",
                              { attrs: { size: "mini", type: "default" } },
                              [_vm._v("CC")]
                            ),
                            contact.name
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(contact.name) +
                                      " <" +
                                      _vm._s(contact.email) +
                                      "> "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(" " + _vm._s(contact.email) + " "),
                                ]),
                          ],
                          1
                        )
                      }),
                      _vm._l(_vm.contact_bcc, function (contact) {
                        return _c(
                          "li",
                          { key: contact.email },
                          [
                            _c(
                              "el-tag",
                              { attrs: { size: "mini", type: "success" } },
                              [_vm._v("BCC")]
                            ),
                            contact.name
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(contact.name) +
                                      " <" +
                                      _vm._s(contact.email) +
                                      "> "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(" " + _vm._s(contact.email) + " "),
                                ]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }