<template>
    <div v-if="loaded">
        <div class="header">
            <el-button @click="goBackAction">Back</el-button>
            <span class="ref">
                /
                <span>
                    {{ estimate_id }} / Invoice {{ invoice.sequence }}/{{
                        payment_plan.quantity
                    }}
                </span>
            </span>
            <span class="header-right">
                <span style="font-size: 11px;">
                    <span style="font-weight: bolder; margin-right: 5px;">
                        Status:
                    </span>
                    <el-tag
                        :type="invoice.status ? 'info' : 'danger'"
                        :disable-transitions="true"
                        size="mini"
                    >
                        {{ invoice.status ?? 'Missing' | capitalize }}
                    </el-tag>
                </span>

                <el-popconfirm
                    v-if="!editing_invoice.invoice_id"
                    class="delete-confirm"
                    placement="top"
                    title="Are you sure you want to delete this invoice?"
                    hide-icon
                    @confirm="handleDeleteInvoice"
                >
                    <el-button slot="reference" plain type="danger">
                        Delete invoice
                    </el-button>
                </el-popconfirm>
            </span>
        </div>

        <hr />

        <paymentplan-invoice
            :payment_plan="payment_plan"
            :invoice="editing_invoice"
            :estimate="estimate"
            @update="handleInvoiceUpdate"
        />

        <div class="section-footer">
            <template v-if="editing_invoice.invoice_id">
                <el-alert
                    v-if="!linked_invoice"
                    :closable="false"
                    type="warning"
                >
                    This invoice has not been imported. Refresh invoices to link
                    it to this payment plan.
                </el-alert>

                <div class="row">
                    <div class="col" v-if="invoice_url">
                        <el-button
                            v-clipboard:copy="invoice_url"
                            readonly
                            @click="showNotification('Xero invoice link')"
                        >
                            Xero invoice link
                        </el-button>
                    </div>
                    <div class="col right">
                        <el-button
                            v-if="linked_invoice"
                            @click="handleViewInvoice"
                        >
                            View invoice
                        </el-button>
                        <template v-if="can_send_email">
                            <div class="email-sent" v-if="email_sent">
                                Sent
                            </div>
                            <el-button
                                v-else
                                plain
                                type="primary"
                                :loading="sending"
                                @click="handleSendEmail"
                            >
                                {{
                                    this.linked_invoice.sent_to_contact
                                        ? 'Resend email'
                                        : 'Send email'
                                }}
                            </el-button>
                        </template>
                    </div>
                </div>
                <div class="last-sent" v-if="invoice.last_sent">
                    Last sent
                    {{ invoice.last_sent.toDate() | dateformat('DD/MM/YYYY') }}
                </div>
            </template>
            <template v-else>
                <el-alert type="info" :closable="false">
                    Changes will apply to this invoice only, and do not affect
                    the rest of the payment plan.
                </el-alert>

                <div class="row">
                    <div class="col right">
                        <el-button
                            :disabled="is_disabled"
                            @click="handleSaveInvoice"
                        >
                            Save changes
                        </el-button>

                        <el-popover
                            v-model="submitConfirmVisible"
                            placement="top"
                            trigger="manual"
                        >
                            <div class="send-confirm-popover">
                                <span class="label">
                                    The due date is either today or in the
                                    past.<br />
                                    Are you sure you want to submit to Xero?
                                </span>
                                <el-button
                                    type="danger"
                                    size="mini"
                                    @click="() => handleSubmitInvoice(true)"
                                >
                                    Confirm & submit
                                </el-button>
                            </div>

                            <el-button
                                :disabled="
                                    !is_accepted || editing_invoice.is_scheduled
                                "
                                :loading="sending"
                                slot="reference"
                                class="submit-button"
                                plain
                                type="primary"
                                @click="() => handleSubmitInvoice(false)"
                            >
                                <span v-if="submitConfirmVisible">
                                    Cancel submit
                                </span>
                                <span v-else>Submit to Xero</span>
                            </el-button>
                        </el-popover>
                    </div>
                </div>
            </template>
        </div>

        <el-alert
            v-if="!has_contact_emails && !invoice_paid"
            type="error"
            class="email-warning"
            :closable="false"
        >
            This payment plan is not configured for emailing.
            <el-button
                class="edit-paymentplan-contacts"
                size="small"
                plain
                type="danger"
                @click="handleEditContacts"
            >
                Edit contacts
            </el-button>
        </el-alert>
        <el-alert
            v-else-if="can_send_email"
            class="email-list"
            :closable="false"
        >
            The following contacts will receive this invoice when emailed:
            <ul>
                <li v-if="contact_addressee.name">
                    {{ contact_addressee.name }}
                    &lt;{{ contact_addressee.email }}&gt;
                </li>
                <li v-else>
                    {{ contact_addressee.email }}
                </li>
                <li v-for="contact of contact_cc" :key="contact.email">
                    <el-tag size="mini" type="default">CC</el-tag>
                    <span v-if="contact.name">
                        {{ contact.name }} &lt;{{ contact.email }}&gt;
                    </span>
                    <span v-else>
                        {{ contact.email }}
                    </span>
                </li>
                <li v-for="contact of contact_bcc" :key="contact.email">
                    <el-tag size="mini" type="success">BCC</el-tag>
                    <span v-if="contact.name">
                        {{ contact.name }} &lt;{{ contact.email }}&gt;
                    </span>
                    <span v-else>
                        {{ contact.email }}
                    </span>
                </li>
            </ul>
        </el-alert>
    </div>
</template>

<script>
import PaymentplanInvoice from '@/pages/paymentplans/components/PaymentPlan_invoice';

import PaymentplanMixin from '@/mixins/paymentplan.mixin';
import ReferenceTemplateMixin from '@/mixins/referencetemplate.mixin';

import {
    submitPaymentPlanInvoiceToXero,
    sendPaymentPlanInvoiceEmail,
} from '@/api/paymentplan';

import {momentWithTz} from '@/utils';
import {Notification} from 'element-ui';

import firebase from 'firebase/app';
import {INVOICE_STATUS, EMAIL_TYPE, PAYMENT_PLAN_INVOICE_STATUS} from '@/enums';
import invoiceMixin from '@/mixins/invoice.mixin';
import dayjs from 'dayjs';

export default {
    name: 'payment-plan-invoice',
    components: {
        PaymentplanInvoice,
    },
    mixins: [ReferenceTemplateMixin, PaymentplanMixin, invoiceMixin],
    data() {
        return {
            invoice: null,
            editing_invoice: null,
            payment_plan: null,
            estimate: null,
            line_items: [],
            loading: false,
            sending: false,

            submitConfirmVisible: false,
            dueDateCustom: null,
            useCustomDueDate: false,
            timezone: process.env.VUE_APP_TIMEZONE,

            email_sent: false,
        };
    },
    computed: {
        estimate_id() {
            if (!this.invoice) return null;
            return this.$options.filters.fireRef2id(this.invoice.estimate);
        },
        client_id() {
            if (this.editing_invoice.client) {
                return this.$options.filters.fireRef2id(
                    this.editing_invoice.client
                );
            }
            return null;
        },
        loaded() {
            return this.invoice && this.payment_plan && this.estimate;
        },
        is_disabled() {
            // check if invoice is valid
            // if scheduled, must have a scheduled date
            return !this.editing_invoice.schedule_date;
        },
        is_accepted() {
            return (
                this.editing_invoice.status ===
                PAYMENT_PLAN_INVOICE_STATUS.ACCEPTED
            );
        },
        invoice_url() {
            return this.invoice.xero?.invoice_url ?? null;
        },
        linked_invoice() {
            if (this.invoice?.invoice_id) {
                return this.$store.getters.invoiceWithId(
                    this.invoice.invoice_id
                );
            }
            return null;
        },
        invoice_paid() {
            return this.linked_invoice?.status === INVOICE_STATUS.PAID;
        },
        due_date_from_terms() {
            if (!this.editing_invoice) return null;
            return this.calculateDueDate(
                momentWithTz(),
                this.editing_invoice.due_value,
                this.editing_invoice.due_terms
            );
        },
        can_send_email() {
            return (
                this.linked_invoice &&
                this.contact_list?.length > 0 &&
                !this.invoice_paid
            );
        },

        contact_list() {
            if (this.client_id) {
                const client = this.$store.getters.clientWithId(this.client_id);
                let contacts = [];

                // Only add primary contact if it has a valid email
                if (client.email) contacts.push(client);

                // Add any additional contacts
                if (client.additional_contacts) {
                    client.additional_contacts.forEach((contact) => {
                        if (contact.include_in_emails) {
                            contacts.push(contact);
                        }
                    });
                }

                return contacts;
            }

            return null;
        },

        has_contact_emails() {
            return this.payment_plan.contact_emails?.length;
        },

        contact_addressee() {
            return this.payment_plan.contact_emails?.find(
                (c) => c.type === EMAIL_TYPE.ADDRESSEE
            );
        },
        contact_cc() {
            return this.payment_plan.contact_emails?.filter(
                (c) => c.type === EMAIL_TYPE.CC
            );
        },
        contact_bcc() {
            return this.payment_plan.contact_emails?.filter(
                (c) => c.type === EMAIL_TYPE.BCC
            );
        },
    },
    watch: {
        '$route.params.invoice_id'() {
            this.init();
        },
        invoice(val) {
            // val will be null when the invoice is deleted
            if (val) {
                this.editing_invoice = {
                    ...val,
                    id: val.id,
                    schedule_date: val.schedule_date?.toDate() ?? null,
                    due_date: val.due_date?.toDate() ?? null,
                };
            }
        },
        due_date_from_terms: {
            handler(newVal, oldVal) {
                if (
                    !this.editing_invoice.is_scheduled ||
                    !this.editing_invoice.due_date
                ) {
                    this.dueDateCustom = newVal;
                } else {
                    const custom = momentWithTz(
                        this.editing_invoice.due_date
                    ).startOf('day');

                    if (momentWithTz(oldVal).startOf('day').isSame(custom)) {
                        this.dueDateCustom = newVal;
                    } else {
                        this.dueDateCustom = custom;
                    }
                }
            },
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.loading = true;

            /* PAYMENT PLAN INVOICE */
            await this.$bind(
                'invoice',
                this.$fire.doc(
                    `payment_plan_invoices/${this.$route.params.invoice_id}`
                ),
                {
                    maxRefDepth: 0,
                }
            ).catch((e) => console.warn('invoice: ', e));

            if (!this.invoice) {
                this.$router.replace({name: 'projects'}).then();
                return;
            }

            /* PAYMENT PLAN */
            await this.$bind(
                'payment_plan',
                this.$fire.doc(
                    `payment_plans/${this.$options.filters.fireRef2id(
                        this.invoice.payment_plan
                    )}`
                ),
                {
                    maxRefDepth: 0,
                }
            ).catch((e) => console.warn('payment_plan: ', e));

            /* ESTIMATE */
            this.$bind(
                'estimate',
                this.$fire.doc(
                    `estimates/${this.$options.filters.fireRef2id(
                        this.invoice?.estimate
                    )}`
                ),
                {
                    maxRefDepth: 0,
                }
            ).catch((e) => console.warn('estimate: ', e));

            this.loading = false;
        },

        showNotification(key) {
            Notification({
                type: 'success',
                title: 'Copied',
                message: `${key} copied to clipboard.`,
            });
        },

        handleInvoiceUpdate(invoice, autosave = false) {
            this.editing_invoice = invoice;
            // if editing an existing invoice, optionally save automatically
            if (this.estimate_id && autosave) {
                this.handleSaveInvoice(false);
            }
        },

        async handleSaveInvoice(closeOnSave = true) {
            // force set reference on save
            this.editing_invoice.reference = this.processTemplate(
                this.editing_invoice.reference_template,
                this.editing_invoice.schedule_date,
                this.editing_invoice.sequence,
                this.payment_plan.quantity
            );

            // force set status
            let invoice_status = 'DRAFT';
            switch (this.estimate.status) {
                case 4: // estimate sent
                    invoice_status = 'PENDING';
                    break;
                case 2: // estimate accepted
                    invoice_status = 'ACCEPTED';
                    break;
                case 3: // estimate declined
                    invoice_status = 'DECLINED';
                    break;
            }
            this.editing_invoice.status = invoice_status;

            await this.$store.dispatch('updatePaymentPlanInvoice', {
                ...this.editing_invoice,
                project: this.$fire.doc(
                    `projects/${this.$options.filters.fireRef2id(
                        this.editing_invoice.project
                    )}`
                ),
                estimate: this.$fire.doc(
                    `estimates/${this.$options.filters.fireRef2id(
                        this.editing_invoice.estimate
                    )}`
                ),
                payment_plan: this.$fire.doc(
                    `payment_plans/${this.$options.filters.fireRef2id(
                        this.editing_invoice.payment_plan
                    )}`
                ),
                client: this.$fire.doc(
                    `clients/${this.$options.filters.fireRef2id(
                        this.editing_invoice.client
                    )}`
                ),
                line_items: this.editing_invoice.line_items.map((item) => {
                    return {
                        ...item,
                        estimate_group: this.$fire.doc(
                            `estimate_groups/${this.$options.filters.fireRef2id(
                                item.estimate_group
                            )}`
                        ),
                    };
                }),
                schedule_date: this.editing_invoice.schedule_date
                    ? momentWithTz(this.editing_invoice.schedule_date)
                          .startOf('day')
                          .add(8, 'hours')
                          .toDate()
                    : null,
                due_date: this.editing_invoice.due_date
                    ? momentWithTz(this.editing_invoice.due_date)
                          .startOf('day')
                          .toDate()
                    : null,
            });

            if (closeOnSave) {
                this.goBackAction();
            }
        },

        async handleDeleteInvoice() {
            // if there are any zero-cost line items, unset the estimate group's is_invoiced flag
            for (const item of this.invoice.line_items) {
                if (item.estimate_group !== 'CREDIT') {
                    const groupRef = this.$fire.doc(item.estimate_group);
                    await groupRef.update({
                        is_invoiced: firebase.firestore.FieldValue.delete(),
                    });
                }
            }

            const planRef = this.$fire.doc(
                `payment_plans/${this.payment_plan.id}`
            );

            if (this.payment_plan.quantity > 1) {
                // if this isn't the last invoice in this plan, resequence the invoices that follow
                if (this.invoice.sequence < this.payment_plan.quantity) {
                    const invSnapshots = await this.$fire
                        .collection('payment_plan_invoices')
                        .where('payment_plan', '==', planRef)
                        .get();
                    invSnapshots.forEach((snapshot) => {
                        const invData = snapshot.data();
                        if (invData.sequence > this.invoice.sequence) {
                            snapshot.ref.update({
                                sequence: invData.sequence - 1,
                            });
                        }
                    });
                }

                // reduce plan quantity by 1
                await planRef.update({
                    quantity: this.payment_plan.quantity - 1,
                });
            } else {
                // remove payment plan
                await planRef.delete();
            }
            await this.$fire
                .doc(`payment_plan_invoices/${this.invoice.id}`)
                .delete();

            this.goBackAction();
        },

        async handleSubmitInvoice(ignore_past_due = false) {
            if (!ignore_past_due) {
                if (this.submitConfirmVisible === true) {
                    // already warned about past due, close warning popup
                    this.submitConfirmVisible = false;
                    return;
                } else {
                    // if due date is in past, show warning
                    const now = dayjs();
                    if (dayjs(this.editing_invoice.due_date).isBefore(now)) {
                        this.submitConfirmVisible = true;
                        return;
                    }
                }
            }
            this.submitConfirmVisible = false;
            this.sending = true;

            // update invoice with new dates
            this.editing_invoice.schedule_date = momentWithTz();
            this.editing_invoice.due_date = this.useCustomDueDate
                ? this.dueDateCustom
                : this.due_date_from_terms;

            await this.handleSaveInvoice(false);

            // submit invoice to Xero
            try {
                await submitPaymentPlanInvoiceToXero(this.editing_invoice.id);
            } catch (e) {
                Notification({
                    type: 'error',
                    title: 'Error',
                    message: 'Could not submit invoice to Xero',
                });
            }

            // refresh invoices for this client
            try {
                await this.updateInvoices();
            } catch (e) {
                Notification({
                    type: 'error',
                    title: 'Error',
                    message: 'Could not refresh invoices',
                });
            }
            this.sending = false;
        },
        handleCustomDueDateSet() {
            this.useCustomDueDate = true;
        },
        goBackAction() {
            this.$router.push({
                name: 'project_detail_paymentplan',
                params: {
                    project_id: this.$route.params.project_id,
                    estimate_id: this.estimate.id,
                },
            });
        },
        handleViewInvoice() {
            this.$router.push({
                name: 'management_invoices_detail',
                params: {
                    invoice_number: this.linked_invoice.invoice_number,
                },
            });
        },
        async handleSendEmail() {
            this.sending = true;

            try {
                await sendPaymentPlanInvoiceEmail(this.editing_invoice.id);
            } catch (e) {
                Notification({
                    type: 'error',
                    title: 'Error',
                    message: 'Could not send email',
                });

                // Abort operation
                this.sending = false;
                return;
            }

            this.email_sent = true;

            // Email has been sent successfully
            Notification({
                type: 'success',
                title: 'Email sent',
                message: 'Estimate has been emailed to contacts',
            });

            // Refresh invoices for this client
            try {
                await this.updateInvoices();
            } catch (e) {
                Notification({
                    type: 'error',
                    title: 'Error',
                    message: 'Could not refresh invoices',
                });
            }

            this.sending = false;
        },

        handleEditContacts() {
            this.$router.push({
                name: 'project_detail_paymentplan_plan',
                params: {
                    plan_id: this.payment_plan.id,
                    invoice_id: this.invoice.id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    span.ref {
        padding: 0 10px;

        span {
            padding: 0 10px;
            font-weight: bold;
        }
    }

    .header-right {
        margin-left: auto;

        > * {
            margin-left: 10px;
        }
    }
}

hr {
    width: 100%;
    margin: 10px 0;
    border: none;
    height: 1px;
    background-color: $border-grey-light;
}

.payment-plan {
    margin-top: 10px;
    padding: 10px;
    background-color: $grey;
    border-radius: 4px;
    min-height: 100px;

    .payment-plan-ref {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 5px;
    }
}

.section-footer {
    .row {
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .col {
            flex: 1;
        }

        .right {
            display: flex;
            justify-content: flex-end;
        }
    }

    .el-alert {
        margin: 10px 0;
    }

    .submit-button {
        margin-left: 10px;
    }

    .contact-list {
        ul {
            li {
                .full-name {
                    font-weight: bold;
                }
                .email {
                    font-style: italic;
                }
            }
        }
    }
}

.send-confirm-popover {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px 20px;

    .options {
        display: flex;
        flex-direction: row;
        align-items: center;

        .option {
            width: 140px;
            text-align: center;

            .label {
                font-size: 11px;

                &.selected {
                    font-weight: bold;
                }
            }

            .due-from-terms {
                border: 1px solid $border-grey;
                border-radius: 4px;
                line-height: 32px;
                background: $transparent-grey;
                font-size: 13px;
            }
        }
    }

    ::v-deep {
        .el-date-editor {
            width: 100%;
        }

        .el-switch {
            margin: 0 10px;
            margin-top: 20px;
        }
    }

    .label {
        font-size: 10px;
        margin-top: 10px;
    }

    .el-button {
        display: block;
        margin-top: 10px;
    }
}

.xero-link {
    margin-top: 10px;
    width: 100%;
    ::v-deep .el-input__inner {
        cursor: pointer;
    }
}

.email-list {
    margin-top: 20px;
    li {
        line-height: 24px;
    }
}

.email-warning {
    margin-top: 20px;
    ::v-deep .el-alert__content {
        width: 100%;
        .el-alert__description {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
.email-sent {
    display: flex;
    align-items: center;
    font-family: 'Arial';
    font-size: 14px;
    color: rgb(96, 98, 102);
    border: 1px solid rgb(220, 223, 230);
    border-radius: 4px;
    padding: 0 20px;
    margin-left: 5px;
    cursor: not-allowed;
    background: $transparent-grey;
}

.last-sent {
    font-size: 11px;
    color: rgba($black, 0.5);
    text-align: right;
}
</style>
